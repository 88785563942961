<template>
  <div class="page-user-detail">
    page {{ title }} is working!
  </div>
</template>

<script lang="js">
import UserDetailPage from './user-detail';

export default UserDetailPage;
</script>

<style lang="scss">
@import "./user-detail";
</style>
